<template>
  <div class="container">
    <a-breadcrumb style="padding: 16px 0; background: #f0f2f5">
      <a-breadcrumb-item>用餐人员管理</a-breadcrumb-item>
      <a-breadcrumb-item>添加用餐人员</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="mainBox" style="padding: 24px">
      
      <div class="agent_inst" v-if="agent_inst">
        <a-button v-if="!code" type="primary" @click="getInstitutionCodeFn"
          >获取加入食堂邀请码</a-button
        >
        <div v-else>
          <div class="flex">
            <h2>{{ code }}</h2>
            <a-button type="primary" v-clipboard:error="onError" v-clipboard:copy="code" v-clipboard:success="onCopy">点击复制</a-button>
          </div>
          <h3 class="tip">将注册邀请码下发至员工，员工使用浏览器（建议手机端打开）登录https：//h5.51baocan.cn，注册时填写加入食堂邀请码，即可加入专属食堂</h3>
        </div>
      </div>
      <div class="not_agent_inst" v-else>
        <a-button class="btn-style" type="primary" @click="showPic"
          >获取加入食堂二维码</a-button
        >
        <a-modal width="410px" v-model="picVisible" :footer="null"  @cancel="closeQrcode">
          <div style="text-align: center;width: 360px;">
          <canvas id="tutorial" width="360" height="480"></canvas>
          <a-button
            v-if="url"
            style="margin: 20px;width: 230px;"
            type="primary"
            icon="printer"
            @click="downloadQrcode"
            >保存图片到本地</a-button>
        </div>
        </a-modal>
      </div>
      <div class="agent_inst">
        <a-button class="btn-style" type="primary" v-if="useLink" @click="getShareLinkFn" v-clipboard:error="onError" v-clipboard:copy="link" v-clipboard:success="onCopyLink">通过微信邀请加入</a-button>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getAddQrcode, getInstitutionCode, getShareLink, getInstProInfo} from "@/request/api";
import util from "@/utils/qrcode";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      url: "",
      agent_inst: false,
      code: "",
      link:'',
      useLink:false,
      picVisible:false
    };
  },
  //监听属性 类似于data概念
  computed: {

  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    DownloadPic() {
      const a = document.createElement("a");
      a.setAttribute("href", this.url);
      a.click();
    },
    showPic() {
      this.url = `https://styf.oss-cn-shenzhen.aliyuncs.com/business/qrcode/${this.global.userInfo.inst_id}/join.jpg`;
      if(process.env.NODE_ENV === 'development'){
        this.url = `https://styf.oss-cn-shenzhen.aliyuncs.com/business/testqrcode/${this.global.userInfo.inst_id}/join.jpg`;
      }
      
      //
      this.picVisible = true; 
      let inst_info = JSON.parse(localStorage.getItem("inst"));
      setTimeout(()=>{
        let canvas = document.getElementById('tutorial')
        //canvas:Canvas元素； imgUrl:二维码URL； title: 食堂名称; id:食堂ID
        util.generateQrcode(canvas,this.url,inst_info.title,inst_info.id,'微信扫码或长按识别二维码加入食堂');
      },500);
      return
    },
    downloadQrcode(){
        let canvas = document.getElementById('tutorial')
        let data = canvas.toDataURL("image/png")
        util.saveCanvasImg(data)
    },
    closeQrcode(){
      let canvas = document.getElementById('tutorial');
      util.removeQrcode(canvas);
      this.picVisible = false;
    },
    beforeGetCode() {
      getAddQrcode().then((res) => {});
    },
    async getConfig(){
      let { data } = await getInstProInfo();
      if(data.invite_status == 1){
        this.useLink = true;
      }
    },
    async getInstitutionCodeFn() {
      let { data } = await getInstitutionCode();
      this.code = data.code;
    },
    onCopy(e){
        this.$message.success('复制成功！')
    },
    // 复制失败
    onError(e){
       this.$message.error('复制失败,您的浏览器暂不支持此功能')
    },
    onCopyLink(){
      this.$message.success('邀请链接复制成功,链接7天后失效,快去转发给用餐人员吧');
    },
    //通过微信获取邀请链接
    async getShareLinkFn(){
      let { data } = await getShareLink();
      this.link = data.data;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.beforeGetCode();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.agent_inst = JSON.parse(localStorage.getItem("agent_inst"));
    this.getConfig();
    this.getShareLinkFn();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.controlBox {
  display: flex;
  .btn {
    margin-bottom: 10px;
    margin-right: 20px;
  }
}
.flex{
  display: flex;
  width: 220px;
  justify-content: space-between;
}
.tip{
  color: gray;
}
.btn-style{
  width: 160px;
  text-align: center;
}
.agent_inst{
  margin-top: 30px;
}
</style>